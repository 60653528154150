"use client";

import { queryClient } from "@/util/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, useEffect } from "react";
import { theme } from "@/theme";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dayjs } from "@/util/dayjs";
import { User } from "@/types";
import { socket } from "@/api/socket";
import { useRouter } from "next/navigation";
import { api } from "@/api/api";
import { produce } from "immer";
import { IncidentViewProvider } from "@/context/incident-context";
import { CallTypesProvider } from "@/context/calltype-context";
import { NuqsAdapter } from "nuqs/adapters/next/app";
import { MenuProvider } from "@/context/menu-context";
import { createEmotionCache } from "@/lib/create-emotion-cache";
import { CacheProvider } from "@emotion/react";
import { NotificationProvider } from "@/context/notification-context";
import { IncidentRefreshProvider } from "@/context/incident-refresh-context";
import { TimeProvider } from "@/context/time-context";

type Props = {
  children: ReactNode;
  self?: User | null;
  accessToken?: string;
};

const clientSideEmotionCache = createEmotionCache();

function ProviderContent({ children, accessToken }: Props) {
  const router = useRouter();

  useEffect(() => {
    if (!accessToken) return;

    const onError = (error: any) => console.error("Socket error", error);
    const onConnect = () => console.log("Socket connected");
    const onDisconnect = () => console.log("Socket disconnected");
    const onConnectTimeout = () => console.error("Socket connection timed out");

    const onSessionDuplicated = async () => {
      await api.post("auth/signOut");
      router.push("/auth/sign-in");
      alert("Your account was signed into from another device");
    };

    socket.on("error", onError);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_timeout", onConnectTimeout);
    socket.on("session.duplicated", onSessionDuplicated);

    socket.auth = { token: accessToken };
    socket.connect();

    return () => {
      socket.removeAllListeners();
      socket.disconnect();
    };
  }, [accessToken, router]);

  return children;
}

export function Providers({ children, self, accessToken }: Props) {
  useEffect(() => {
    if (self) {
      queryClient.setQueryData(["self"], self);
    }
  }, []);
  return (
    <CacheProvider value={clientSideEmotionCache}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            dateLibInstance={dayjs}
          >
            <MenuProvider>
              <ProviderContent self={self} accessToken={accessToken}>
                {accessToken ? (
                  <CallTypesProvider self={self} accessToken={accessToken}>
                    <NotificationProvider>
                      <IncidentViewProvider>
                        <IncidentRefreshProvider>
                          <TimeProvider>
                            <NuqsAdapter>{children}</NuqsAdapter>
                          </TimeProvider>
                        </IncidentRefreshProvider>
                      </IncidentViewProvider>
                    </NotificationProvider>
                  </CallTypesProvider>
                ) : (
                  <NuqsAdapter>{children}</NuqsAdapter>
                )}
              </ProviderContent>
            </MenuProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </CacheProvider>
  );
}
