// "use server";

// import { User } from "@/types";
// import { JWTVerifyResult, jwtVerify } from "jose";
// import { cookies } from "next/headers";

// const encoder = new TextEncoder();

// export async function getSelf() {
//   const accessToken = cookies().get("accessToken")?.value;

//   if (!accessToken) {
//     return null;
//   }

//   try {
//     const result: JWTVerifyResult = await jwtVerify(
//       accessToken,
//       encoder.encode(process.env.FIRE_NOTIFICATION_JWT_SECRET!),
//     );

//     const user = result.payload as unknown as JWTVerifyResult & User;

//     if (user.archived || user.archivedAt) {
//       cookies().delete("accessToken");
//       return null;
//     }

//     return user;
//   } catch (error) {
//     console.error(error);
//     return null;
//   }
// }

// export async function isRole({
//   user,
//   roleId,
// }: {
//   user?: User;
//   roleId: string;
// }) {
//   return user?.Roles?.some?.((role) => role.id === roleId) || false;
// }

// export async function isBusinessAdmin({
//   user,
//   businessId,
// }: {
//   user?: User;
//   businessId: string;
// }) {
//   const isAdmin = await isRole({
//     user,
//     roleId: "admin",
//   });

//   return isAdmin && user?.BusinessId === businessId;
// }

"use server";

import { User } from "@/types";
import { JWTVerifyResult, jwtVerify } from "jose";
import { cookies } from "next/headers";

const encoder = new TextEncoder();

export async function getSelf() {
  const accessToken = cookies().get("accessToken")?.value;

  if (!accessToken) {
    return null;
  }

  try {
    const result: JWTVerifyResult = await jwtVerify(
      accessToken,
      encoder.encode(process.env.FIRE_NOTIFICATION_JWT_SECRET!),
    );

    const user = result.payload as unknown as JWTVerifyResult & User;

    if (user.archived || user.archivedAt) {
      cookies().delete("accessToken");
      return null;
    }

    return user;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function isRole({
  user,
  roleId,
}: {
  user?: User;
  roleId: string;
}) {
  return user?.Roles?.some?.((role) => role.id === roleId) || false;
}

export async function isBusinessAdmin({
  user,
  businessId,
}: {
  user?: User;
  businessId: string;
}) {
  const isAdmin = await isRole({
    user,
    roleId: "admin",
  });

  return isAdmin && user?.BusinessId === businessId;
}
