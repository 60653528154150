"use client";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface TimeContextType {
  formatTime: (
    timestamp: string | Date | null | undefined,
    options?: {
      condensed?: boolean;
      format?: string;
      statusPrefix?: string;
      showTimezoneIndicator?: boolean;
    },
  ) => string;

  adjustTime: (
    timestamp: string | Date | null | undefined,
  ) => dayjs.Dayjs | null;

  currentTimezone: string;
  isUsingLocalTimezone: boolean;

  isToday: (timestamp: string | Date) => boolean;
  isYesterday: (timestamp: string | Date) => boolean;
  getRelativeTime: (timestamp: string | Date) => string;
}

const TimeContext = createContext<TimeContextType | undefined>(undefined);

const PST_TIMEZONE = "America/Los_Angeles";

// Import types for the hooks you're using
import { useSystemInfo } from "@/context/system-info";
import { useSelf } from "@/hooks/useSelf";

export function TimeProvider({ children }: { children: ReactNode }) {
  const { data: self } = useSelf();
  const { timezone } = useSystemInfo();
  const [localTimezone, setLocalTimezone] = useState<string | null>(null);

  useEffect(() => {
    try {
      // Try to get timezone from browser
      const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let detected = null;

      if (browserTz) {
        setLocalTimezone(browserTz);
      } else {
        // Fallback to dayjs guess
        detected = dayjs.tz.guess();
        setLocalTimezone(detected || null);
      }
    } catch (error) {
      setLocalTimezone(null);
    }
  }, []);

  // Determine which timezone to use based on user role
  const hasStaffRole =
    self?.Roles?.some((role) => role.isStaff === true) || false;
  const isEmployee = self?.id === "employee" || false;
  const isAdmin =
    self?.isAdmin ||
    self?.Roles?.some((role) => role.name === "Admin") ||
    false;
  const shouldUseStaffTimezone = hasStaffRole && !isEmployee && !isAdmin;

  let currentTimezone = PST_TIMEZONE;
  let isUsingLocalTimezone = false;

  if (shouldUseStaffTimezone) {
    currentTimezone = PST_TIMEZONE;
  } else {
    if (timezone) {
      currentTimezone = timezone;
      isUsingLocalTimezone = true;
    } else if (localTimezone) {
      currentTimezone = localTimezone;
      isUsingLocalTimezone = true;
    }
  }

  const adjustTime = (timestamp: string | Date | null | undefined) => {
    if (!timestamp) return null;

    try {
      const utcDate = dayjs.utc(timestamp);

      if (!utcDate.isValid()) {
        return null;
      }

      if (isUsingLocalTimezone) {
        return utcDate.local();
      } else {
        return utcDate.tz(currentTimezone);
      }
    } catch (error) {
      return null;
    }
  };

  const formatTime = (
    timestamp: string | Date | null | undefined,
    options: {
      condensed?: boolean;
      format?: string;
      statusPrefix?: string;
      showTimezoneIndicator?: boolean;
    } = {},
  ) => {
    if (!timestamp) return "";

    try {
      if (
        typeof timestamp === "string" &&
        (!timestamp.trim() || timestamp === "undefined" || timestamp === "null")
      ) {
        return "";
      }

      const time = adjustTime(timestamp);

      if (!time || !time.isValid()) {
        return "";
      }

      const {
        condensed = false,
        format,
        statusPrefix,
        showTimezoneIndicator = false,
      } = options;
      const defaultFormat = condensed ? "h:mmA MM-DD-YY" : "MMM D, YYYY h:mm A";

      let result = "";

      let formatString = format || defaultFormat;

      if (condensed) {
        try {
          const timeValue = time.format("h:mm");
          const amPm = time.format("A");
          const dateValue = time.format("MM-DD-YY");
          result = `${timeValue}${amPm} ${dateValue}`;

          if (statusPrefix) {
            result = `${statusPrefix} ${result}`;
          }

          result = result.toUpperCase();
        } catch (err) {
          result = time.format("YYYY-MM-DD HH:mm");
        }
      } else {
        try {
          result = time.format(formatString);
        } catch (err) {
          result = time.format("YYYY-MM-DD HH:mm");
        }
      }

      if (showTimezoneIndicator) {
        const tzAbbr =
          currentTimezone === PST_TIMEZONE
            ? time.format("z")
            : isUsingLocalTimezone
              ? "LOCAL"
              : time.format("z");

        result = `${result} (${tzAbbr})`;
      }

      return result;
    } catch (error) {
      return "";
    }
  };

  const isToday = (timestamp: string | Date) => {
    const adjusted = adjustTime(timestamp);
    return adjusted?.isToday() || false;
  };

  const isYesterday = (timestamp: string | Date) => {
    const adjusted = adjustTime(timestamp);
    return adjusted?.isYesterday() || false;
  };

  const getRelativeTime = (timestamp: string | Date) => {
    const adjusted = adjustTime(timestamp);
    return adjusted?.fromNow() || "";
  };

  const value = {
    formatTime,
    adjustTime,
    currentTimezone,
    isUsingLocalTimezone,
    isToday,
    isYesterday,
    getRelativeTime,
  };

  return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>;
}

export function useTime() {
  const context = useContext(TimeContext);
  if (context === undefined) {
    throw new Error("useTime must be used within a TimeProvider");
  }
  return context;
}
