// "use client";
// import React, { createContext, useContext, useState, useCallback } from "react";

// interface IncidentRefreshContextType {
//   refreshCounter: number;
//   triggerRefresh: () => void;
// }

// const IncidentRefreshContext = createContext<IncidentRefreshContextType>({
//   refreshCounter: 0,
//   triggerRefresh: () => {},
// });

// export const useIncidentRefresh = () => useContext(IncidentRefreshContext);

// export function IncidentRefreshProvider({
//   children,
// }: {
//   children: React.ReactNode;
// }) {
//   const [refreshCounter, setRefreshCounter] = useState(0);

//   const triggerRefresh = useCallback(() => {
//     setRefreshCounter((prev) => prev + 1);
//   }, []);

//   return (
//     <IncidentRefreshContext.Provider value={{ refreshCounter, triggerRefresh }}>
//       {children}
//     </IncidentRefreshContext.Provider>
//   );
// }

"use client";
import React, { createContext, useContext, useState, useCallback } from "react";

interface SocketIncident {
  incidentId: string;
  description: string;
  address: {
    line1: string;
    city: string;
    state: string;
    county: string;
  };
  structureType: string;
  incidentType: string;
  priority: string | number;
  creatorId: string;
}

interface IncidentRefreshContextType {
  refreshCounter: number;
  triggerRefresh: () => void;
  addNewIncident: (incident: SocketIncident) => void;
  newIncidents: SocketIncident[];
  clearNewIncidents: () => void;
}

const IncidentRefreshContext = createContext<IncidentRefreshContextType>({
  refreshCounter: 0,
  triggerRefresh: () => {},
  addNewIncident: () => {},
  newIncidents: [],
  clearNewIncidents: () => {},
});

export const useIncidentRefresh = () => useContext(IncidentRefreshContext);

export function IncidentRefreshProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [newIncidents, setNewIncidents] = useState<SocketIncident[]>([]);

  const triggerRefresh = useCallback(() => {
    setRefreshCounter((prev) => prev + 1);
  }, []);

  const addNewIncident = useCallback((incident: SocketIncident) => {
    setNewIncidents((prevIncidents) => {
      if (prevIncidents.some((inc) => inc.incidentId === incident.incidentId)) {
        return prevIncidents;
      }
      return [...prevIncidents, incident];
    });
  }, []);

  const clearNewIncidents = useCallback(() => {
    setNewIncidents([]);
  }, []);

  return (
    <IncidentRefreshContext.Provider
      value={{
        refreshCounter,
        triggerRefresh,
        addNewIncident,
        newIncidents,
        clearNewIncidents,
      }}
    >
      {children}
    </IncidentRefreshContext.Provider>
  );
}
