"use client";

import React, { createContext, useContext, useState, useEffect } from "react";
import {
  Snackbar,
  Alert,
  Box,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import { Close } from "@mui/icons-material";

type NotificationType = "success" | "info" | "warning" | "error";

interface NotificationOptions {
  type?: NotificationType;
  duration?: number;
  title?: string;
  avatar?: string;
  action?: React.ReactNode;
}

interface NotificationContextType {
  showNotification: (message: string, options?: NotificationOptions) => void;
  setPendingNotification: (
    message: string,
    options?: NotificationOptions,
  ) => void;
}

let pendingGlobalNotification: {
  message: string;
  options: NotificationOptions;
  timestamp: number;
} | null = null;

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined,
);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState<NotificationOptions>({
    type: "info",
    duration: 5000,
    title: "",
    avatar: "",
    action: null,
  });

  const showNotification = (
    message: string,
    newOptions: NotificationOptions = {},
  ) => {
    console.log("Showing notification:", message, newOptions);
    setMessage(message);
    setOptions({ ...options, ...newOptions });
    setOpen(true);
  };

  const setPendingNotification = (
    message: string,
    notificationOptions: NotificationOptions = {},
  ) => {
    pendingGlobalNotification = {
      message,
      options: notificationOptions,
      timestamp: Date.now(),
    };
    console.log("Set pending global notification:", pendingGlobalNotification);

    try {
      localStorage.setItem(
        "pendingNotification",
        JSON.stringify({
          message,
          options: notificationOptions,
          timestamp: Date.now(),
        }),
      );
    } catch (e) {
      console.error("Error setting localStorage:", e);
    }
  };

  useEffect(() => {
    if (open && options.duration) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, options.duration);

      return () => clearTimeout(timer);
    }
  }, [open, options.duration]);

  useEffect(() => {
    console.log(
      "NotificationProvider mounted, checking for pending notifications",
    );

    if (pendingGlobalNotification) {
      console.log(
        "Found pending global notification:",
        pendingGlobalNotification,
      );

      if (Date.now() - pendingGlobalNotification.timestamp < 30000) {
        showNotification(
          pendingGlobalNotification.message,
          pendingGlobalNotification.options,
        );
      }

      pendingGlobalNotification = null;
      // console.log("Cleared pending global notification");
    } else {
      try {
        const pendingNotificationStr = localStorage.getItem(
          "pendingNotification",
        );
        // console.log(
        //   "Checking localStorage for notifications:",
        //   pendingNotificationStr,
        // );

        if (pendingNotificationStr) {
          const notification = JSON.parse(pendingNotificationStr);

          if (Date.now() - notification.timestamp < 30000) {
            showNotification(notification.message, notification.options);
          } else {
            console.log(
              "Notification too old:",
              Date.now() - notification.timestamp,
            );
          }

          localStorage.removeItem("pendingNotification");
          console.log("Cleared notification from localStorage");
        }
      } catch (e) {
        console.error("Error checking localStorage:", e);
        localStorage.removeItem("pendingNotification");
      }
    }
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        setPendingNotification,
      }}
    >
      {children}
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          zIndex: 9999999,
          position: "fixed",
          maxWidth: "400px",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={options.type || "info"}
          sx={{
            width: "100%",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {options.avatar && (
              <Avatar
                src={options.avatar}
                sx={{ width: 24, height: 24, mr: 1 }}
              />
            )}
            <Box>
              {options.title && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  fontWeight="bold"
                >
                  {options.title}
                </Typography>
              )}
              <Typography variant="body2">{message}</Typography>
            </Box>
          </Box>
          {options.action && <Box sx={{ mt: 1 }}>{options.action}</Box>}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }
  return context;
};
