"use client";

import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { api } from "@/api/api";
import type {
  StructureType,
  IncidentType,
  DamageType,
  IncidentPreset,
  IncidentPresetShortcut,
  UserStateSubscription,
  UserPresetSubscription,
  Industry,
  User,
  IncidentFilter,
} from "@/types";

interface ConfigResponse {
  success: boolean;
  structureTypes: StructureType[];
  incidentTypes: IncidentType[];
  damageTypes: DamageType[];
  incidentPresets: IncidentPreset[];
  incidentPresetShortcuts: IncidentPresetShortcut[];
  industries: Industry[];
  userPlaceholderImage: string;
}

interface BusinessSubscriptionsResponse {
  success: boolean;
  presets: Array<{ Preset: Pick<IncidentPreset, "label"> }>;
  stateIds: string[];
  cityIds: string[];
}

interface UserSubscriptionsResponse {
  success: boolean;
  presetSubscriptions: UserPresetSubscription[];
  stateSubscriptions: UserStateSubscription[];
  cityExclusions: string[];
  countyExclusions: string[];
}

interface LocationItem {
  allCounties: boolean;
  id: string;
  name: string;
  type: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  } | null;
}

interface CountyItem extends LocationItem {
  stateId: string;
  stateName: string;
  allCities?: boolean;
}

interface CityItem extends LocationItem {
  countyId: string;
  countyName: string;
  stateId: string;
  stateName: string;
}

interface ZipCodeItem extends LocationItem {
  stateId: string;
  stateName: string;
  radius: number;
}

interface UserLocationPreferencesResponse {
  success: boolean;
  subscriptions: {
    states: LocationItem[];
    counties: CountyItem[];
    cities: CityItem[];
    zipCodes: ZipCodeItem[];
  };
  exclusions: {
    counties: CountyItem[];
    cities: CityItem[];
    zipCodes: ZipCodeItem[];
  };
  subscriptionsByState: Array<{
    stateInfo: LocationItem;
    counties: CountyItem[];
    cities: CityItem[];
    zipCodes: ZipCodeItem[];
  }>;
  exclusionsByState: Array<{
    stateInfo: LocationItem;
    counties: CountyItem[];
    cities: CityItem[];
    zipCodes: ZipCodeItem[];
  }>;
  counts: {
    subscriptions: {
      states: number;
      counties: number;
      cities: number;
      zipCodes: number;
      total: number;
    };
    exclusions: {
      counties: number;
      cities: number;
      zipCodes: number;
      total: number;
    };
  };
}

interface BusinessLocationsResponse {
  success: boolean;
  subscriptions: {
    states: LocationItem[];
    counties: CountyItem[];
    cities: CityItem[];
  };
  exclusions: {
    counties: CountyItem[];
    cities: CityItem[];
  };
}

interface CallTypesContextValue {
  structureTypes: StructureType[];
  incidentTypes: IncidentType[];
  damageTypes: DamageType[];
  incidentPresets: IncidentPreset[];
  presetShortcuts: IncidentPresetShortcut[];

  // User subscriptions
  userSubscriptions: {
    presetSubscriptions: UserPresetSubscription[];
    stateSubscriptions: UserStateSubscription[];
    cityExclusions: string[];
    countyExclusions: string[];
    activeSubscriptions: Set<string>;
  };

  userLocationPreferences: {
    isLoading: boolean;
    error: Error | null;
    data: UserLocationPreferencesResponse | null | undefined;
  };

  businessSubscriptions: {
    presets: Array<{ Preset: Pick<IncidentPreset, "label"> }>;
    stateIds: string[];
    cityIds: string[];
    states: LocationItem[];
    counties: CountyItem[];
    cities: CityItem[];
    countyExclusions: CountyItem[];
    cityExclusions: CityItem[];
    activePresetLabels: Set<string>;
  };

  activePresets: IncidentPreset[];

  togglePresetSubscription: {
    mutate: (presetLabel: string) => void;
    isPending: boolean;
  };
  bulkUpdatePresetSubscriptions: {
    mutate: () => void;
    isPending: boolean;
  };
  deleteAllPresetSubscriptions: {
    mutate: () => void;
    isPending: boolean;
  };

  toggleBusinessPresetSubscription: {
    mutate: (data: { businessId: string; label: string }) => void;
    isPending: boolean;
  };

  updateIncidentFilter: {
    mutate: (filter: Partial<IncidentFilter>) => void;
    isPending: boolean;
  };

  currentFilter: IncidentFilter | undefined;
  isLoading: boolean;
  error: Error | null;
}

interface CallTypesProviderProps {
  children: ReactNode;
  self?: User | null;
  accessToken?: string;
}

const defaultCallTypesContext: CallTypesContextValue = {
  structureTypes: [],
  incidentTypes: [],
  damageTypes: [],
  incidentPresets: [],
  presetShortcuts: [],
  activePresets: [],

  userSubscriptions: {
    presetSubscriptions: [],
    stateSubscriptions: [],
    cityExclusions: [],
    countyExclusions: [],
    activeSubscriptions: new Set<string>(),
  },

  userLocationPreferences: {
    isLoading: false,
    error: null,
    data: undefined,
  },

  businessSubscriptions: {
    presets: [],
    stateIds: [],
    cityIds: [],
    states: [],
    counties: [],
    cities: [],
    countyExclusions: [],
    cityExclusions: [],
    activePresetLabels: new Set<string>(),
  },

  togglePresetSubscription: {
    mutate: () =>
      console.warn("togglePresetSubscription called outside of provider"),
    isPending: false,
  },
  toggleBusinessPresetSubscription: {
    mutate: () =>
      console.warn(
        "toggleBusinessPresetSubscription called outside of provider",
      ),
    isPending: false,
  },
  bulkUpdatePresetSubscriptions: {
    mutate: () =>
      console.warn("bulkUpdatePresetSubscriptions called outside of provider"),
    isPending: false,
  },
  deleteAllPresetSubscriptions: {
    mutate: () =>
      console.warn("deleteAllPresetSubscriptions called outside of provider"),
    isPending: false,
  },
  updateIncidentFilter: {
    mutate: () =>
      console.warn("updateIncidentFilter called outside of provider"),
    isPending: false,
  },
  currentFilter: undefined,
  isLoading: false,
  error: null,
};

export const CallTypesContext = createContext<CallTypesContextValue>(
  defaultCallTypesContext,
);

export function CallTypesProvider({
  children,
  self,
  accessToken,
}: CallTypesProviderProps) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<Error | null>(null);
  const userId = self?.id;
  const businessId = self?.BusinessId;

  useEffect(() => {
    if (!accessToken) {
      queryClient.clear();
    }
  }, [accessToken, queryClient]);

  const queryKeys = {
    config: ["config"] as const,
    userSubscriptions: (userId: string) =>
      ["user-subscriptions", userId] as const,
    userLocationPreferences: (userId: string) =>
      ["user-location-preferences", userId] as const,
    businessSubscriptions: (businessId: string) =>
      ["businesses", businessId, "subscriptions"] as const,
    businessExclusions: (businessId: string) =>
      ["businesses", businessId, "exclusions"] as const,
    incidents: ["incidents"] as const,
  };

  // Configuration fetch
  const {
    data: config,
    isLoading: isConfigLoading,
    error: configError,
  } = useQuery({
    queryKey: queryKeys.config,
    queryFn: async () => {
      const response = await api.get<ConfigResponse>("config");
      return response.data;
    },
    enabled: !!accessToken && !!userId,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  const {
    data: userSubscriptions,
    isLoading: isUserSubsLoading,
    error: userSubsError,
  } = useQuery({
    queryKey: queryKeys.userSubscriptions(userId ?? ""),
    queryFn: async () => {
      if (!userId || !accessToken) return null;

      const [
        presetsResponse,
        statesResponse,
        cityExclusionsResponse,
        countyExclusionsResponse,
      ] = await Promise.all([
        api.get<{ success: boolean; subscriptions: UserPresetSubscription[] }>(
          `users/${userId}/subscriptions/presets`,
        ),
        api.get<{ success: boolean; subscriptions: UserStateSubscription[] }>(
          `users/${userId}/subscriptions/states`,
        ),
        api.get<{ success: boolean; exclusions: string[] }>(
          `users/${userId}/exclusions/cities`,
        ),
        api.get<{ success: boolean; exclusions: string[] }>(
          `users/${userId}/exclusions/counties`,
        ),
      ]);

      return {
        success: true,
        presetSubscriptions: presetsResponse.data.subscriptions,
        stateSubscriptions: statesResponse.data.subscriptions,
        cityExclusions: cityExclusionsResponse.data.exclusions,
        countyExclusions: countyExclusionsResponse.data.exclusions,
      };
    },
    enabled: !!userId && !!accessToken,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  // New query for user location preferences
  const {
    data: userLocationPreferences,
    isLoading: isUserLocationPreferencesLoading,
    error: userLocationPreferencesError,
  } = useQuery({
    queryKey: queryKeys.userLocationPreferences(userId ?? ""),
    queryFn: async () => {
      if (!userId || !accessToken) return null;

      try {
        const response = await api.get<UserLocationPreferencesResponse>(
          `users/${userId}/location-preferences`,
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching user location preferences:", error);
        return null;
      }
    },
    enabled: !!userId && !!accessToken,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  const {
    data: businessPresetsData,
    isLoading: isBusinessPresetsLoading,
    error: businessPresetsError,
  } = useQuery({
    queryKey: queryKeys.businessSubscriptions(businessId || ""),
    queryFn: async () => {
      if (!businessId || !accessToken) return null;
      const response = await api.get<BusinessSubscriptionsResponse>(
        `businesses/${businessId}/subscriptions`,
      );
      return response.data;
    },
    enabled: !!businessId && !!accessToken,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  const {
    data: businessLocationData,
    isLoading: isBusinessLocationLoading,
    error: businessLocationError,
  } = useQuery({
    queryKey: queryKeys.businessExclusions(businessId || ""),
    queryFn: async () => {
      if (!businessId || !accessToken) return null;
      const response = await api.get<BusinessLocationsResponse>(
        `businesses/${businessId}/exclusions`,
      );
      return response.data;
    },
    enabled: !!businessId && !!accessToken,
    staleTime: 5 * 60 * 1000,
    retry: 2,
  });

  const { data: currentFilter } = useQuery<IncidentFilter>({
    queryKey: ["filters", "incidents"],
    enabled: !!accessToken,
  });

  const togglePresetMutation = useMutation({
    mutationFn: async (label: string) => {
      if (!userId || !accessToken)
        throw new Error("No user ID or access token available");
      return api.put(`users/${userId}/subscriptions/presets`, null, {
        params: { label },
      });
    },
    onSuccess: () => {
      if (userId) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.userSubscriptions(userId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.incidents,
        });
      }
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const toggleBusinessPresetMutation = useMutation({
    mutationFn: async (data: { businessId: string; label: string }) => {
      return api.put(
        `businesses/${data.businessId}/subscriptions/presets`,
        undefined,
        {
          params: {
            label: data.label,
          },
        },
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.businessSubscriptions(variables.businessId),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.incidents,
      });
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const bulkUpdateMutation = useMutation({
    mutationFn: async () => {
      if (!userId || !accessToken)
        throw new Error("No user ID or access token available");
      return api.post(`users/${userId}/subscriptions/presets/all`);
    },
    onSuccess: () => {
      if (userId) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.userSubscriptions(userId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.incidents,
        });
      }
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const deleteAllPresetsMutation = useMutation({
    mutationFn: async () => {
      if (!userId || !accessToken)
        throw new Error("No user ID or access token available");
      return api.post(`users/${userId}/subscriptions/presets/delete`);
    },
    onSuccess: () => {
      if (userId) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.userSubscriptions(userId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.incidents,
        });
      }
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const updateFilterMutation = useMutation({
    mutationFn: (filter: Partial<IncidentFilter>) => {
      queryClient.setQueryData<IncidentFilter>(
        ["filters", "incidents"],
        (storedFilter) => {
          if (!storedFilter) {
            return undefined;
          }
          return {
            ...storedFilter,
            ...filter,
          };
        },
      );
      return Promise.resolve();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["businesses"] });
      queryClient.invalidateQueries({ queryKey: ["config"] });
    },
  });

  const userActiveSubscriptions = useMemo(() => {
    if (!userSubscriptions?.presetSubscriptions) return new Set<string>();
    return new Set(
      userSubscriptions.presetSubscriptions.map(
        (sub) => `${sub.StructureTypeId}-${sub.IncidentTypeId}`,
      ),
    );
  }, [userSubscriptions?.presetSubscriptions]);

  const businessActivePresetLabels = useMemo(() => {
    if (!businessPresetsData?.presets) return new Set<string>();
    return new Set<string>(
      businessPresetsData.presets
        .filter((item) => item.Preset && item.Preset.label)
        .map((item) => item.Preset.label as string),
    );
  }, [businessPresetsData?.presets]);

  const activePresets = useMemo(() => {
    if (!config?.incidentPresets) return [];
    return config.incidentPresets.filter((preset) => preset.active === true);
  }, [config?.incidentPresets]);

  useEffect(() => {
    return () => {
      if (userId) {
        queryClient.removeQueries({
          queryKey: queryKeys.userSubscriptions(userId),
        });
        queryClient.removeQueries({
          queryKey: queryKeys.userLocationPreferences(userId),
        });
      }

      if (businessId) {
        queryClient.removeQueries({
          queryKey: queryKeys.businessSubscriptions(businessId),
        });
        queryClient.removeQueries({
          queryKey: queryKeys.businessExclusions(businessId),
        });
      }

      queryClient.removeQueries({
        queryKey: queryKeys.config,
      });
    };
  }, [queryClient, userId, businessId]);

  const value: CallTypesContextValue = {
    structureTypes: config?.structureTypes || [],
    incidentTypes: config?.incidentTypes || [],
    damageTypes: config?.damageTypes || [],
    incidentPresets: config?.incidentPresets || [],
    presetShortcuts: config?.incidentPresetShortcuts || [],
    activePresets,

    userSubscriptions: {
      presetSubscriptions: userSubscriptions?.presetSubscriptions || [],
      stateSubscriptions: userSubscriptions?.stateSubscriptions || [],
      cityExclusions: userSubscriptions?.cityExclusions || [],
      countyExclusions: userSubscriptions?.countyExclusions || [],
      activeSubscriptions: userActiveSubscriptions,
    },

    userLocationPreferences: {
      isLoading: isUserLocationPreferencesLoading,
      error: userLocationPreferencesError,
      data: userLocationPreferences,
    },

    businessSubscriptions: {
      presets: businessPresetsData?.presets || [],
      stateIds: businessPresetsData?.stateIds || [],
      cityIds: businessPresetsData?.cityIds || [],
      states: businessLocationData?.subscriptions?.states || [],
      counties: businessLocationData?.subscriptions?.counties || [],
      cities: businessLocationData?.subscriptions?.cities || [],
      countyExclusions: businessLocationData?.exclusions?.counties || [],
      cityExclusions: businessLocationData?.exclusions?.cities || [],
      activePresetLabels: businessActivePresetLabels,
    },

    togglePresetSubscription: {
      mutate: togglePresetMutation.mutate,
      isPending: togglePresetMutation.isPending,
    },
    toggleBusinessPresetSubscription: {
      mutate: toggleBusinessPresetMutation.mutate,
      isPending: toggleBusinessPresetMutation.isPending,
    },
    bulkUpdatePresetSubscriptions: {
      mutate: bulkUpdateMutation.mutate,
      isPending: bulkUpdateMutation.isPending,
    },
    deleteAllPresetSubscriptions: {
      mutate: deleteAllPresetsMutation.mutate,
      isPending: deleteAllPresetsMutation.isPending,
    },
    updateIncidentFilter: {
      mutate: updateFilterMutation.mutate,
      isPending: updateFilterMutation.isPending,
    },
    currentFilter,
    isLoading:
      isConfigLoading ||
      isUserSubsLoading ||
      isUserLocationPreferencesLoading ||
      isBusinessPresetsLoading ||
      isBusinessLocationLoading,
    error:
      configError ||
      userSubsError ||
      userLocationPreferencesError ||
      businessPresetsError ||
      businessLocationError ||
      error,
  };

  return (
    <CallTypesContext.Provider value={value}>
      {children}
    </CallTypesContext.Provider>
  );
}

export const useCallTypes = () => {
  const context = useContext(CallTypesContext);
  if (!context) {
    throw new Error("useCallTypes must be used within CallTypesProvider");
  }
  return context;
};
