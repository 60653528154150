"use client";
import {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { IncidentFilter, Incident } from "@/types";
import { useSelf } from "@/hooks/useSelf";
import { useIncidentFilter } from "@/hooks/useIncidentFilter";
import { storage } from "@/util/storage";

interface IncidentViewContextType {
  category: any;
  setCategory: (category: any) => void;
  queryParams: Partial<IncidentFilter>;
  filterIncidents: (incidents: Incident[]) => Incident[];
}

const defaultIncidentViewContext: IncidentViewContextType = {
  category: "paged",
  setCategory: () => console.warn("setCategory called outside of provider"),
  queryParams: {},
  filterIncidents: (incidents) => incidents,
};

export const IncidentViewContext = createContext<IncidentViewContextType>(
  defaultIncidentViewContext,
);

export function useIncidentView() {
  const context = useContext(IncidentViewContext);
  if (!context) {
    throw new Error("useIncidentView must be used within IncidentViewProvider");
  }
  return context;
}

export function IncidentViewProvider({ children }: { children: ReactNode }) {
  const [category, setCategory] = useState<any>("paged");
  const [isInitialized, setIsInitialized] = useState(false);
  const { data: self } = useSelf();
  const { data: incidentFilter } = useIncidentFilter();

  useEffect(() => {
    const loadSavedCategory = () => {
      try {
        const savedCategory = localStorage.getItem("incidentViewCategory");
        if (savedCategory) {
          setCategory(savedCategory);
        }
        setIsInitialized(true);
      } catch (error) {
        console.warn("Error loading saved category:", error);
        setIsInitialized(true);
      }
    };

    loadSavedCategory();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      try {
        localStorage.setItem("incidentViewCategory", category);
      } catch (error) {
        console.warn("Error saving category:", error);
      }
    }
  }, [category, isInitialized]);

  const stateIdString = useMemo(() => {
    if (!self?.UserStateSubscriptions?.length) return "";
    return self.UserStateSubscriptions.map(
      (sub: { StateId: any }) => sub.StateId,
    ).join(",");
  }, [self]);

  const countyInclusions = useMemo(() => {
    if (!self?.UserCountySubscriptions?.length) return "";
    return self.UserCountySubscriptions.map(
      (sub: { CountyId: any }) => sub.CountyId,
    ).join(",");
  }, [self]);

  const queryParams = useMemo<Partial<IncidentFilter>>(() => {
    if (!incidentFilter) {
      return {
        limit: 100,
        propertyAddress: true,
      };
    }

    const baseFilters: Partial<IncidentFilter> = {
      limit: 100,
      propertyAddress: true,
      from: incidentFilter.from,
      to: incidentFilter.to,
      presets: incidentFilter.presets,
      allCountyStateIds: stateIdString ? stateIdString.split(",") : [],
      countyInclusions: countyInclusions ? countyInclusions.split(",") : [],
    };

    if (category === "all") {
      return {
        ...baseFilters,
        category: "all",
      };
    } else if (category === "paged") {
      return {
        ...baseFilters,
        active: true,
        verified: true,
        incidentStatus: "active",
        paged: true,
      };
    } else if (category === "unpaged") {
      return {
        ...baseFilters,
        active: true,
        verified: false,
        incidentStatus: "active",
        paged: false,
      };
    } else {
      return {
        ...baseFilters,
        active: true,
        incidentStatus: "active",
      };
    }
  }, [category, incidentFilter, stateIdString, countyInclusions]);

  const filterIncidents = useCallback(
    (incidents: Incident[]): Incident[] => {
      if (!incidents?.length) return [];

      switch (category) {
        case "unpaged":
          return incidents.filter((inc) => !inc.paged);
        case "paged":
          return incidents.filter((inc) => inc.paged);
        case "favorited":
        case "archived":
          return incidents;
        default:
          return incidents;
      }
    },
    [category],
  );

  // Only render children when initialization is complete
  if (!isInitialized) {
    return null; // Or a loading indicator
  }

  return (
    <IncidentViewContext.Provider
      value={{
        category,
        setCategory,
        queryParams,
        filterIncidents,
      }}
    >
      {children}
    </IncidentViewContext.Provider>
  );
}
